<app-card [backgroundImage]="data.Race?.ImageId | appCardBackground: 'races'">
  <app-card-header>
    <app-card-title class="app-text-ellipsis"
      [matTooltip]="data.Name.toLocaleUpperCase()"
      [matTooltipShowDelay]="500">
      {{data.Name}}
    </app-card-title>
  </app-card-header>
  <div>
    {{data.Race?.Name || 'Раса не выбрана'}},
    {{(data.Classes | characterCardClasses) || 'Класс не выбран'}}
  </div>
</app-card>