import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

import { ApiCharacterListItem } from '@api/types';

import { CardBackgroundPipe, CardComponent, CardHeaderComponent, CardTitleDirective } from '@modules/card';

import { CharacterCardClassesPipe } from './character-card-classes.pipe';

@Component({
  selector: 'app-character-card',
  templateUrl: './character-card.component.html',
  styleUrls: ['./character-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CardComponent,
    CardHeaderComponent,
    CardTitleDirective,
    MatTooltip,
    CardBackgroundPipe,
    CharacterCardClassesPipe,
  ],
})
export class CharacterCardComponent {
  @Input() data!: ApiCharacterListItem;
}
